import React from "react"
import classNames from "classnames"

import MobileWindow from "./MobileWindow"
import CustomCollapsible from "../Windows/Collapsible"

import faqs from "../Windows/utils/faqs.json"
import * as styles from "./utils/mobilePages.module.scss"

const FAQPage = () => {
    return (
        <MobileWindow title="frequently asked questions">
            <div className={classNames("p-2 m-2", styles["faqs__content"])}>
                {faqs.map((faq) => (
                    <div key={faq.question}>
                        <CustomCollapsible
                            title={faq.question}>
                            <p
                                dangerouslySetInnerHTML={
                                    {__html: faq.content}
                                }
                            />
                        </CustomCollapsible>
                        <hr className="my-3"/>
                    </div>
                    )
                )}
            </div>
        </MobileWindow>
    )
}
export default FAQPage